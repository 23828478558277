import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import './Introduce2.scss';
import Aboutlogo from '../../statics/images/v3/common/logo.svg';
import AboutGcLogo from '../../statics/images/v3/about/logo_GC.png';
import TitleAbout from '../../statics/images/v3/bg-aboutus.jpg';

import itemCheck from '../../statics/images/v3/introduce/2/img-check.png';

import item1 from '../../statics/images/v3/introduce/2/img-item1.png';

import item21 from '../../statics/images/v3/introduce/2/img-item21.png';
import item22 from '../../statics/images/v3/introduce/2/img-item22.png';
import item23 from '../../statics/images/v3/introduce/2/img-item23.png';
import item24 from '../../statics/images/v3/introduce/2/img-item24.png';
import item25 from '../../statics/images/v3/introduce/2/img-item25.png';

import item51 from '../../statics/images/v3/introduce/2/img-item51.png';
import item521 from '../../statics/images/v3/introduce/2/img-item521.png';
import item522 from '../../statics/images/v3/introduce/2/img-item522.png';
import item523 from '../../statics/images/v3/introduce/2/img-item523.png';

import item611 from '../../statics/images/v3/introduce/2/img-item611.png';
import item612 from '../../statics/images/v3/introduce/2/img-item612.png';
import item613 from '../../statics/images/v3/introduce/2/img-item613.png';
import item614 from '../../statics/images/v3/introduce/2/img-item614.png';

import { isBrowser, isMobile } from 'react-device-detect';
import Carousel from 'nuka-carousel';

export default function Introduce2(props: RouteComponentProps) {

  return (
    <div className='introduce2-wrapper'>
      <div className='common-header'>
        <img src={TitleAbout} alt='' />
        <h1>서비스 소개</h1>
      </div>

      <section className='introduce2-item1-wrapper'>
        <div className='desc-wrapper'>
          <span>
            (주)그라운드컨트롤에서 운영하는 {isBrowser && <br />}
            부동산개발 공동 참여 플랫폼
          </span>

          <img src={Aboutlogo} alt='' />

          <span>
            <strong>누구나 소액 자금</strong>으로도 <br />
            <strong>부동산 개발에 참여</strong> 할 수 있는 <br />
            <strong>기회를 마련</strong>합니다.
          </span>
        </div>

        <img src={item1} alt='' />
      </section>

      <section className='introduce2-item2-wrapper'>
        <div>
          <div className='title-wrapper'>
            <h1>지오팝에서는</h1>
            <span>복잡했던 부동산 개발 프로세스가 {isMobile && <br />} 쉽고 투명하게 진행됩니다.</span>
          </div>

          <div className='desc-wrapper'>
            {isMobile ?
              <Carousel slidesToShow={1}
                        autoplay={true}
                        wrapAround={true}
                        autoplayInterval={2000}
                        defaultControlsConfig={{
                          nextButtonStyle: {
                            display: 'none',
                          },
                          prevButtonStyle: {
                            display: 'none',
                          },
                          pagingDotsStyle: {
                            display: 'none',
                          },
                        }}
              >
                <div className='introduce2-item2'>
                  <img src={item21} alt='item21' />

                  <div className='introduce2-item2-desc'>
                    <svg xmlns='http://www.w3.org/2000/svg' width='43' height='67' viewBox='0 0 43 67' fill='none'>
                      <g filter='url(#filter0_i_314_5444)'>
                        <path d='M0.0200001 67V54.04H14.24V15.97H1.91V6.07C9.11 4.72 13.79 2.92 18.47 0.0399984H30.26V54.04H42.32V67H0.0200001Z'
                              fill='#FFC120' />
                      </g>
                      <defs>
                        <filter id='filter0_i_314_5444' x='0.0195312' y='0.0400391' width='42.3008' height='70.96' filterUnits='userSpaceOnUse'
                                color-interpolation-filters='sRGB'>
                          <feFlood flood-opacity='0' result='BackgroundImageFix' />
                          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
                          <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
                          <feOffset dy='4' />
                          <feGaussianBlur stdDeviation='2' />
                          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
                          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
                          <feBlend mode='normal' in2='shape' result='effect1_innerShadow_314_5444' />
                        </filter>
                      </defs>
                    </svg>

                    <span>
                  복잡하고 소모적인 부동산 관련
                  업무의 온라인화로 <strong>효율성과</strong>
                  <strong>접근성을 강화</strong>합니다.
              </span>
                  </div>
                </div>

                <div className='introduce2-item2'>
                  <img src={item22} alt='item22' />

                  <div className='introduce2-item2-desc'>
                    <svg xmlns='http://www.w3.org/2000/svg' width='49' height='69' viewBox='0 0 49 69' fill='none'>
                      <g filter='url(#filter0_i_314_5445)'>
                        <path
                          d='M1.78 69V59.82C18.52 44.61 29.77 32.46 29.77 22.74C29.77 16.62 26.35 13.29 21.04 13.29C16.45 13.29 12.85 16.35 9.7 19.77L0.97 11.13C7.54 4.2 13.66 0.779999 23.2 0.779999C36.16 0.779999 45.16 9.06 45.16 21.84C45.16 33.36 35.26 46.05 25 56.4C28.33 55.95 32.92 55.5 35.89 55.5H48.22V69H1.78Z'
                          fill='#FFC120' />
                      </g>
                      <defs>
                        <filter id='filter0_i_314_5445' x='0.969727' y='0.780029' width='47.25' height='72.22' filterUnits='userSpaceOnUse'
                                color-interpolation-filters='sRGB'>
                          <feFlood flood-opacity='0' result='BackgroundImageFix' />
                          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
                          <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
                          <feOffset dy='4' />
                          <feGaussianBlur stdDeviation='2' />
                          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
                          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
                          <feBlend mode='normal' in2='shape' result='effect1_innerShadow_314_5445' />
                        </filter>
                      </defs>
                    </svg>

                    <span>
                  부동산 개발에 함께할 <strong>참여자들을</strong> <br />
                  <strong>모아 조합을 만듭니다.</strong>
                </span>
                  </div>
                </div>

                <div className='introduce2-item2'>
                  <img src={item23} alt='item23' />

                  <div className='introduce2-item2-desc'>
                    <svg xmlns='http://www.w3.org/2000/svg' width='48' height='71' viewBox='0 0 48 71' fill='none'>
                      <g filter='url(#filter0_i_314_5447)'>
                        <path
                          d='M23.11 70.26C12.49 70.26 5.02 66.57 0.16 60.9L7.54 50.82C11.59 54.69 16.27 57.3 21.49 57.3C27.7 57.3 31.75 54.69 31.75 49.65C31.75 43.89 28.69 40.47 14.65 40.47V29.22C26.08 29.22 29.68 25.71 29.68 20.4C29.68 15.81 26.98 13.29 22.03 13.29C17.53 13.29 14.11 15.36 10.06 18.87L1.96 9.06C8.17 3.84 14.83 0.779999 22.75 0.779999C36.61 0.779999 45.7 7.26 45.7 19.23C45.7 25.89 42.1 31.11 34.9 33.99V34.44C42.37 36.69 47.86 42.09 47.86 50.73C47.86 63.24 36.43 70.26 23.11 70.26Z'
                          fill='#FFC120' />
                      </g>
                      <defs>
                        <filter id='filter0_i_314_5447' x='0.160156' y='0.780029' width='47.7002' height='73.48' filterUnits='userSpaceOnUse'
                                color-interpolation-filters='sRGB'>
                          <feFlood flood-opacity='0' result='BackgroundImageFix' />
                          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
                          <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
                          <feOffset dy='4' />
                          <feGaussianBlur stdDeviation='2' />
                          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
                          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
                          <feBlend mode='normal' in2='shape' result='effect1_innerShadow_314_5447' />
                        </filter>
                      </defs>
                    </svg>

                    <span>
                  부지 매입부터 매각까지 전 과정을 <br />
                  <strong>조합 명의로 함께 수행</strong>합니다.
                </span>
                  </div>
                </div>

                <div className='introduce2-item2'>
                  <img src={item24} alt='item24' />

                  <div className='introduce2-item2-desc'>
                    <svg xmlns='http://www.w3.org/2000/svg' width='52' height='67' viewBox='0 0 52 67' fill='none'>
                      <g filter='url(#filter0_i_314_5449)'>
                        <path
                          d='M16.28 38.11H29.06V28.3C29.06 24.07 29.51 17.68 29.69 13.45H29.33C27.62 17.23 25.82 21.1 23.93 24.88L16.28 38.11ZM51.47 38.11V50.26H44V67H29.06V50.26H0.98V39.19L24.29 0.0399984H44V38.11H51.47Z'
                          fill='#FFC120' />
                      </g>
                      <defs>
                        <filter id='filter0_i_314_5449' x='0.980469' y='0.0400391' width='50.4893' height='70.96' filterUnits='userSpaceOnUse'
                                color-interpolation-filters='sRGB'>
                          <feFlood flood-opacity='0' result='BackgroundImageFix' />
                          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
                          <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
                          <feOffset dy='4' />
                          <feGaussianBlur stdDeviation='2' />
                          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
                          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
                          <feBlend mode='normal' in2='shape' result='effect1_innerShadow_314_5449' />
                        </filter>
                      </defs>
                    </svg>

                    <span>
                  전자투표와 총회로 의사결정을 <br />
                  <strong>투명하고 편리하게 진행</strong>합니다.
                </span>
                  </div>
                </div>

                <div className='introduce2-item2'>
                  <img src={item25} alt='item25' />

                  <div className='introduce2-item2-desc'>
                    <svg xmlns='http://www.w3.org/2000/svg' width='48' height='69' viewBox='0 0 48 69' fill='none'>
                      <g filter='url(#filter0_i_314_5451)'>
                        <path
                          d='M23.65 68.26C12.94 68.26 5.56 64.21 0.25 58.99L7.54 48.91C11.32 52.51 16 55.3 21.31 55.3C27.79 55.3 31.84 51.79 31.84 44.86C31.84 38.11 27.79 34.51 22.03 34.51C18.34 34.51 16.54 35.23 12.58 37.75L5.56 33.16L7.27 0.0399984H44.89V13.36H21.04L20.05 24.7C22.48 23.71 24.46 23.35 26.98 23.35C38.32 23.35 47.86 30.01 47.86 44.5C47.86 59.53 36.52 68.26 23.65 68.26Z'
                          fill='#FFC120' />
                      </g>
                      <defs>
                        <filter id='filter0_i_314_5451' x='0.25' y='0.0400391' width='47.6104' height='72.22' filterUnits='userSpaceOnUse'
                                color-interpolation-filters='sRGB'>
                          <feFlood flood-opacity='0' result='BackgroundImageFix' />
                          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
                          <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
                          <feOffset dy='4' />
                          <feGaussianBlur stdDeviation='2' />
                          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
                          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
                          <feBlend mode='normal' in2='shape' result='effect1_innerShadow_314_5451' />
                        </filter>
                      </defs>
                    </svg>

                    <span>
                      완공된 건축물을 매각/임대하고 <br />
                      조합 구성원들이 <strong>수익을 나눠 갖습니다.</strong>
                    </span>
                  </div>
                </div>
              </Carousel> :
              <>
                <div className='introduce2-item2'>
                  <img src={item21} alt='item21' />

                  <div className='introduce2-item2-desc'>
                    <svg xmlns='http://www.w3.org/2000/svg' width='43' height='67' viewBox='0 0 43 67' fill='none'>
                      <g filter='url(#filter0_i_314_5444)'>
                        <path d='M0.0200001 67V54.04H14.24V15.97H1.91V6.07C9.11 4.72 13.79 2.92 18.47 0.0399984H30.26V54.04H42.32V67H0.0200001Z'
                              fill='#FFC120' />
                      </g>
                      <defs>
                        <filter id='filter0_i_314_5444' x='0.0195312' y='0.0400391' width='42.3008' height='70.96' filterUnits='userSpaceOnUse'
                                color-interpolation-filters='sRGB'>
                          <feFlood flood-opacity='0' result='BackgroundImageFix' />
                          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
                          <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
                          <feOffset dy='4' />
                          <feGaussianBlur stdDeviation='2' />
                          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
                          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
                          <feBlend mode='normal' in2='shape' result='effect1_innerShadow_314_5444' />
                        </filter>
                      </defs>
                    </svg>

                    <span>
                  복잡하고 소모적인 부동산 관련 <br />
                  업무의 온라인화로 <strong>효율성과</strong> <br />
                  <strong>접근성을 강화</strong>합니다.
              </span>
                  </div>
                </div>

                <div className='introduce2-item2'>
                  <img src={item22} alt='item22' />

                  <div className='introduce2-item2-desc'>
                    <svg xmlns='http://www.w3.org/2000/svg' width='49' height='69' viewBox='0 0 49 69' fill='none'>
                      <g filter='url(#filter0_i_314_5445)'>
                        <path
                          d='M1.78 69V59.82C18.52 44.61 29.77 32.46 29.77 22.74C29.77 16.62 26.35 13.29 21.04 13.29C16.45 13.29 12.85 16.35 9.7 19.77L0.97 11.13C7.54 4.2 13.66 0.779999 23.2 0.779999C36.16 0.779999 45.16 9.06 45.16 21.84C45.16 33.36 35.26 46.05 25 56.4C28.33 55.95 32.92 55.5 35.89 55.5H48.22V69H1.78Z'
                          fill='#FFC120' />
                      </g>
                      <defs>
                        <filter id='filter0_i_314_5445' x='0.969727' y='0.780029' width='47.25' height='72.22' filterUnits='userSpaceOnUse'
                                color-interpolation-filters='sRGB'>
                          <feFlood flood-opacity='0' result='BackgroundImageFix' />
                          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
                          <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
                          <feOffset dy='4' />
                          <feGaussianBlur stdDeviation='2' />
                          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
                          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
                          <feBlend mode='normal' in2='shape' result='effect1_innerShadow_314_5445' />
                        </filter>
                      </defs>
                    </svg>

                    <span>
                  부동산 개발에 함께할 <br /><strong>참여자들을</strong>
                  <strong>모아 조합을 만듭니다.</strong>
                </span>
                  </div>
                </div>

                <div className='introduce2-item2'>
                  <img src={item23} alt='item23' />

                  <div className='introduce2-item2-desc'>
                    <svg xmlns='http://www.w3.org/2000/svg' width='48' height='71' viewBox='0 0 48 71' fill='none'>
                      <g filter='url(#filter0_i_314_5447)'>
                        <path
                          d='M23.11 70.26C12.49 70.26 5.02 66.57 0.16 60.9L7.54 50.82C11.59 54.69 16.27 57.3 21.49 57.3C27.7 57.3 31.75 54.69 31.75 49.65C31.75 43.89 28.69 40.47 14.65 40.47V29.22C26.08 29.22 29.68 25.71 29.68 20.4C29.68 15.81 26.98 13.29 22.03 13.29C17.53 13.29 14.11 15.36 10.06 18.87L1.96 9.06C8.17 3.84 14.83 0.779999 22.75 0.779999C36.61 0.779999 45.7 7.26 45.7 19.23C45.7 25.89 42.1 31.11 34.9 33.99V34.44C42.37 36.69 47.86 42.09 47.86 50.73C47.86 63.24 36.43 70.26 23.11 70.26Z'
                          fill='#FFC120' />
                      </g>
                      <defs>
                        <filter id='filter0_i_314_5447' x='0.160156' y='0.780029' width='47.7002' height='73.48' filterUnits='userSpaceOnUse'
                                color-interpolation-filters='sRGB'>
                          <feFlood flood-opacity='0' result='BackgroundImageFix' />
                          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
                          <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
                          <feOffset dy='4' />
                          <feGaussianBlur stdDeviation='2' />
                          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
                          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
                          <feBlend mode='normal' in2='shape' result='effect1_innerShadow_314_5447' />
                        </filter>
                      </defs>
                    </svg>

                    <span>
                  부지 매입부터 매각까지 전 과정을 <br />
                  <strong>조합 명의로 함께 수행</strong>합니다.
                </span>
                  </div>
                </div>

                <div className='introduce2-item2'>
                  <img src={item24} alt='item24' />

                  <div className='introduce2-item2-desc'>
                    <svg xmlns='http://www.w3.org/2000/svg' width='52' height='67' viewBox='0 0 52 67' fill='none'>
                      <g filter='url(#filter0_i_314_5449)'>
                        <path
                          d='M16.28 38.11H29.06V28.3C29.06 24.07 29.51 17.68 29.69 13.45H29.33C27.62 17.23 25.82 21.1 23.93 24.88L16.28 38.11ZM51.47 38.11V50.26H44V67H29.06V50.26H0.98V39.19L24.29 0.0399984H44V38.11H51.47Z'
                          fill='#FFC120' />
                      </g>
                      <defs>
                        <filter id='filter0_i_314_5449' x='0.980469' y='0.0400391' width='50.4893' height='70.96' filterUnits='userSpaceOnUse'
                                color-interpolation-filters='sRGB'>
                          <feFlood flood-opacity='0' result='BackgroundImageFix' />
                          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
                          <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
                          <feOffset dy='4' />
                          <feGaussianBlur stdDeviation='2' />
                          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
                          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
                          <feBlend mode='normal' in2='shape' result='effect1_innerShadow_314_5449' />
                        </filter>
                      </defs>
                    </svg>

                    <span>
                  전자투표와 총회로 의사결정을 <br />
                  <strong>투명하고 편리하게 진행</strong>합니다.
                </span>
                  </div>
                </div>

                <div className='introduce2-item2'>
                  <img src={item25} alt='item25' />

                  <div className='introduce2-item2-desc'>
                    <svg xmlns='http://www.w3.org/2000/svg' width='48' height='69' viewBox='0 0 48 69' fill='none'>
                      <g filter='url(#filter0_i_314_5451)'>
                        <path
                          d='M23.65 68.26C12.94 68.26 5.56 64.21 0.25 58.99L7.54 48.91C11.32 52.51 16 55.3 21.31 55.3C27.79 55.3 31.84 51.79 31.84 44.86C31.84 38.11 27.79 34.51 22.03 34.51C18.34 34.51 16.54 35.23 12.58 37.75L5.56 33.16L7.27 0.0399984H44.89V13.36H21.04L20.05 24.7C22.48 23.71 24.46 23.35 26.98 23.35C38.32 23.35 47.86 30.01 47.86 44.5C47.86 59.53 36.52 68.26 23.65 68.26Z'
                          fill='#FFC120' />
                      </g>
                      <defs>
                        <filter id='filter0_i_314_5451' x='0.25' y='0.0400391' width='47.6104' height='72.22' filterUnits='userSpaceOnUse'
                                color-interpolation-filters='sRGB'>
                          <feFlood flood-opacity='0' result='BackgroundImageFix' />
                          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
                          <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
                          <feOffset dy='4' />
                          <feGaussianBlur stdDeviation='2' />
                          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
                          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
                          <feBlend mode='normal' in2='shape' result='effect1_innerShadow_314_5451' />
                        </filter>
                      </defs>
                    </svg>

                    <span>
                  완공된 건축물을 매각/임대하고 <br />
                  조합 구성원들이 <strong>수익을 나눠 갖습니다.</strong>
                </span>
                  </div>
                </div>
              </>
            }
          </div>
        </div>
      </section>

      <section className='introduce2-item3-wrapper'>
        <h1>지오팝은 약속드립니다.</h1>

        <div className='desc-wrapper'>
          <div className='introduce2-item3'>
            <img src={itemCheck} alt={'check'} />

            <span>
              그라운드컨트롤도 직접 조합원으로 <strong>함께 참여</strong>합니다.
            </span>
          </div>

          <div className='introduce2-item3'>
            <img src={itemCheck} alt={'check'} />

            <span>
              자체적으로 보유한 부동산 {isMobile && <br />} 데이터베이스를 참고하고 업계 전문가들의 {isBrowser && <br />}
              분석을 통해 <strong>최적의 매물을 선별하여 추천</strong>합니다.
            </span>
          </div>

          <div className='introduce2-item3'>
            <img src={itemCheck} alt={'check'} />
            <span>
              토지 계약 단계부터 신탁관리를 {isMobile && <br />}통해 {isBrowser && <br />}
              <strong>소중한 자산을 안전하게 {isMobile && <br />}관리</strong>합니다.
            </span>
          </div>

          <div className='introduce2-item3'>
            <img src={itemCheck} alt={'check'} />

            <span>
              국내 최고의 설계사, 건축사, 건설사, 법무법인, 회계법인, {isBrowser && <br />}
              세무법인 등과 함께합니다.
            </span>
          </div>

          <div className='introduce2-item3'>
            <img src={itemCheck} alt={'check'} />

            <span>
              <strong>부동산 시장에 더 많은 기회를 제공</strong>하고 {isBrowser && <br />}
              모두가 상생할 수 있는 {isMobile && <br />} 환경을 만들기 위해 노력합니다.
            </span>
          </div>
        </div>
      </section>

      <section className='introduce2-item4-wrapper'>
        <div>
          <iframe
            src='https://www.youtube.com/embed/0Bh8b4ObWWI'
            title='YouTube video player'
            frameBorder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            allowFullScreen
          ></iframe>
        </div>
      </section>

      <section className='introduce2-item5-wrapper'>
        <div>
          <div className='title-wrapper'>
            <h1><img src={AboutGcLogo} alt={'logo'} /> 은 <br /></h1>
            <small>
              부동산 개발사업 활성화를 통해 {isMobile && <br />} 부동산 시장에 새로운 경험을 선사합니다.
            </small>
          </div>

          <div className='introduce2-item51-wrapper'>
            <img src={item51} alt={'introduce2-item51'} />

            <div className='introduce2-item51'>
              <h1>
                더욱 많은 이들에게 다양한 <br />
                참여 기회를 제공할 수는 없을까?
              </h1>

              <span>
                소수에게만 가능했던 부동산 개발시장의 <br />
                진입장벽을 낮춰 <strong>누구나 쉽게</strong> 부동산 개발의 <br />
                매력적인 <strong>기회를 누릴 수 있도록 투명한</strong> <br />
                <strong> 부동산 참여의 새로운 방법</strong>을 고민했습니다.
              </span>
            </div>
          </div>

          <div className='introduce2-item52-wrapper'>
            <h1>
              부동산, 금융, IT 각 분야 {isMobile && <br />}최고 수준의 전문가들이 <br />
              고안해낸 부동산 공동 개발 방법
            </h1>

            <div className='introduce2-item52'>
              <img src={item521} alt={'introduce2-item521'} />
              <span>
                그라운드컨트롤은 부동산 데이터를 {isMobile && <br />} 분석·표준화하고 {isBrowser && <br />}
                개발 가치가 높은 매물을 선별하는 알고리즘을 만들어냈습니다.
              </span>
            </div>

            <div className='introduce2-item52'>
              <img src={item522} alt={'introduce2-item522'} />
              <span>
                부동산 분야의 비대칭적 정보력을 해소하고 <br />
                효율적인 부동산 개발사업으로 비용을 절감하고 <br />
                시장참여 기회 확대로 사회적 이익을 도모합니다.
              </span>
            </div>

            <div className='introduce2-item52'>
              <img src={item523} alt={'introduce2-item523'} />
              <span>
                더 많은 이들에게 쉽고 색다른 부동산 경험을 선사하고 {isBrowser && <br />}
                고객 모두가 신뢰하고 만족하는 서비스와 <br />
                기술 개발을 위해 항상 최선을 다합니다.
              </span>
            </div>
          </div>
        </div>
      </section>

      <section className='introduce2-item6-wrapper'>
        <div className='introduce2-item6'>
          <img src={item611} alt={'introduce2-item611'} />
          <span>
            일반적 부동산 투자는 완공 후 월세와 같은 2차 수익에만 의존합니다. <br />
            또한 완공된 건축물을 매매하기 때문에 진입비용이 상당히 높습니다.
          </span>
        </div>

        <div className='introduce2-item6'>
          <img src={item612} alt={'introduce2-item612'} />
          <span>
            지오팝의 부동산 공동 개발 프로젝트는 {isBrowser && <br />}
            부동산 개발 초기 단계부터 참여하여 상대적으로 낮은 비용으로 <br />
            1차 수익, 즉 개발과정에서 발생하는 가치 상승분까지 수익화 합니다.
          </span>
        </div>

        <div className='introduce2-item6'>
          <img src={item613} alt={'introduce2-item613'} />
          <span>
            지오팝의 부동산 개발 공동 참여 방식은​ 여럿이 함께 참여함으로써 {isBrowser && <br />}
            개발 관련 비용을 분담하고​ 개발 완료 시 발생 가능한 수익을 함께 공유합니다.
          </span>
        </div>

        <div className='introduce2-item6'>
          <img src={item614} alt={'introduce2-item614'} />
          <span>
            방대한 양의 부동산 데이터를 수집하고 가공, 분석하여 개발 가치 있는 {isBrowser && <br />}
            매물을 선정하고 공동 참여자들을 모집해 개발을 수행하고 수익을 배분합니다.
          </span>
        </div>
      </section>

      <section className='about-history-wrapper'>
        <div>
          <h2 className='about-title'>연혁</h2>
          <div className='about-history'>
            <div className='history-table'>
              <div className='year'>
                <mark>2023</mark>
                <ul>
                  <li>
                    <strong className='month'>06</strong>
                    <div className='cont'>
                      <strong>지오팝(GeoPop) 서비스 출시</strong>
                    </div>
                  </li>
                </ul>
              </div>

              <div className='year'>
                <mark>2022</mark>
                <ul>
                  <li>
                    <strong className='month'>08</strong>
                    <div className='cont'>
                      <strong>기업부설연구소 설립</strong>
                      {isMobile && <br />}(인증 한국산업기술진흥협회)
                    </div>
                  </li>
                  <li>
                    <strong className='month'>07</strong>
                    <div className='cont'>
                      <strong>22년 대구TIPS프로그램 선정</strong> {isMobile && <br />}(주관 대구창조경제혁신센터)
                    </div>
                  </li>
                  <li>
                    <strong className='month'>06</strong>
                    <div className='cont'>
                      <strong>K-Global 액셀러레이팅 {isMobile && <br />}프로그램 선정</strong> (주최 과학기술정보통신부)
                    </div>
                  </li>
                  <li>
                    <strong className='month'>05</strong>
                    <div className='cont'>
                      <strong>팁스(TIPS) 창업성장기술개발사업 {isMobile && <br />}선정</strong>(주관 중소벤처기업부)
                    </div>
                  </li>
                  <li>
                    <strong className='month'>02</strong>
                    <div className='cont'>
                      <strong>시드투자 유치 (VC)</strong>
                    </div>
                  </li>
                </ul>
              </div>
              <div className='year'>
                <mark>2020</mark>
                <ul>
                  <li>
                    <strong className='month'>03</strong>
                    <div className='cont'>
                      <strong>기술보증기금 신기술사업 선정 및 {isMobile && <br />}벤처기업인증</strong>
                    </div>
                  </li>
                </ul>
              </div>
              <div className='year'>
                <mark>2019</mark>
                <ul>
                  <li>
                    <strong className='month'>08</strong>
                    <div className='cont'>
                      <strong>법인 설립</strong>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
