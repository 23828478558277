import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import './Introduce3.scss';

import { isBrowser, isMobile } from 'react-device-detect';

import item1 from '../../statics/images/v3/introduce/3/img-item31.png';
import item2 from '../../statics/images/v3/introduce/3/img-item32.png';
import item3 from '../../statics/images/v3/introduce/3/img-item33.png';
import item4 from '../../statics/images/v3/introduce/3/img-item34.png';

export default function Introduce3(props: RouteComponentProps) {

  return (
    <div className='introduce3-wrapper'>

      {
        isMobile ?
          <div className='common-header'>
            <h1>개발 성공 사례</h1>
          </div> :
          <div className='introduce3-header-wrapper'>
            <h1>개발 성공 사례 <strong>(구의동 다세대 주택)</strong></h1>
          </div>
      }

      <section className='introduce3-item1-wrapper'>
        {
          isMobile &&
          <div className='introduce3-header-wrapper'>
            <h1><strong>구의동 다세대 주택</strong></h1>
          </div>
        }

        <span>
          안녕하세요. (주)그라운드컨트롤 서태식 대표입니다. <br />
          이 페이지에서는 구의동 다세대 주택이 성공적이었다고 평가하는 이유를 한번 정리해 보려 합니다.
        </span>

        <h1 style={{ paddingTop: '60px' }}>아이디어의 시작</h1>

        <img style={{ maxWidth: '590px', margin: '0 auto', display: 'flex' }} src={item1} alt={''} />

        <h2 style={{ color: '#777', textAlign: 'start' }}>구의동 다세대 주택의 시작은</h2>

        <ul>
          <li>집장사가 짓는 집과 비슷한 공사비를 들여 {isMobile && <br/>} 높은 퀄리티의 집을 지을 수 있을까?</li>
          <li>퀄리티를 올렸을 때 시장에서 평가받을 수 {isMobile && <br/>} 있을까?</li>
        </ul>

        <h3 style={{ color: '#777', textAlign: 'start' }}>이 두 가지를 실험하는 것이었습니다.</h3>

        <h1>문제점의 도출</h1>
        <h3 style={{ color: '#777', paddingTop: '20px', paddingLeft: isBrowser ? '20px' : 0, lineHeight: '26px' }}>그럼 제가 퀄리티가 낮다고 얘기하는 이유는 {isMobile && <br/>}어떤 것들이 있을까요?</h3>

        <div className={'introduce3-list-wrapper'}>
          <div className={'introduce3-list-item-wrapper'}>
            <svg xmlns='http://www.w3.org/2000/svg' width='22' height='34' viewBox='0 0 43 67' fill='none'>
              <g filter='url(#filter0_i_314_5444)'>
                <path d='M0.0200001 67V54.04H14.24V15.97H1.91V6.07C9.11 4.72 13.79 2.92 18.47 0.0399984H30.26V54.04H42.32V67H0.0200001Z'
                      fill='#FFC120' />
              </g>
              <defs>
                <filter id='filter0_i_314_5444' x='0.0195312' y='0.0400391' width='42.3008' height='70.96' filterUnits='userSpaceOnUse'
                        color-interpolation-filters='sRGB'>
                  <feFlood flood-opacity='0' result='BackgroundImageFix' />
                  <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
                  <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
                  <feOffset dy='4' />
                  <feGaussianBlur stdDeviation='2' />
                  <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
                  <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
                  <feBlend mode='normal' in2='shape' result='effect1_innerShadow_314_5444' />
                </filter>
              </defs>
            </svg>

            <h2 style={{ color: '#0466C8' }}>
              획일적인 매스, 불편한 톤 앤 매너
            </h2>
          </div>

          <div className={'introduce3-list-item-wrapper'}>
            <svg xmlns='http://www.w3.org/2000/svg' width='24' height='34' viewBox='0 0 49 69' fill='none'>
              <g filter='url(#filter0_i_314_5445)'>
                <path
                  d='M1.78 69V59.82C18.52 44.61 29.77 32.46 29.77 22.74C29.77 16.62 26.35 13.29 21.04 13.29C16.45 13.29 12.85 16.35 9.7 19.77L0.97 11.13C7.54 4.2 13.66 0.779999 23.2 0.779999C36.16 0.779999 45.16 9.06 45.16 21.84C45.16 33.36 35.26 46.05 25 56.4C28.33 55.95 32.92 55.5 35.89 55.5H48.22V69H1.78Z'
                  fill='#FFC120' />
              </g>
              <defs>
                <filter id='filter0_i_314_5445' x='0.969727' y='0.780029' width='47.25' height='72.22' filterUnits='userSpaceOnUse'
                        color-interpolation-filters='sRGB'>
                  <feFlood flood-opacity='0' result='BackgroundImageFix' />
                  <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
                  <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
                  <feOffset dy='4' />
                  <feGaussianBlur stdDeviation='2' />
                  <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
                  <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
                  <feBlend mode='normal' in2='shape' result='effect1_innerShadow_314_5445' />
                </filter>
              </defs>
            </svg>

            <h2 style={{ color: '#0466C8' }}>
              시대착오적인 기본에 어긋난 평면
            </h2>
          </div>

          <div className={'introduce3-list-item-wrapper'}>
            <svg xmlns='http://www.w3.org/2000/svg' width='24' height='36' viewBox='0 0 48 71' fill='none'>
              <g filter='url(#filter0_i_314_5447)'>
                <path
                  d='M23.11 70.26C12.49 70.26 5.02 66.57 0.16 60.9L7.54 50.82C11.59 54.69 16.27 57.3 21.49 57.3C27.7 57.3 31.75 54.69 31.75 49.65C31.75 43.89 28.69 40.47 14.65 40.47V29.22C26.08 29.22 29.68 25.71 29.68 20.4C29.68 15.81 26.98 13.29 22.03 13.29C17.53 13.29 14.11 15.36 10.06 18.87L1.96 9.06C8.17 3.84 14.83 0.779999 22.75 0.779999C36.61 0.779999 45.7 7.26 45.7 19.23C45.7 25.89 42.1 31.11 34.9 33.99V34.44C42.37 36.69 47.86 42.09 47.86 50.73C47.86 63.24 36.43 70.26 23.11 70.26Z'
                  fill='#FFC120' />
              </g>
              <defs>
                <filter id='filter0_i_314_5447' x='0.160156' y='0.780029' width='47.7002' height='73.48' filterUnits='userSpaceOnUse'
                        color-interpolation-filters='sRGB'>
                  <feFlood flood-opacity='0' result='BackgroundImageFix' />
                  <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
                  <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
                  <feOffset dy='4' />
                  <feGaussianBlur stdDeviation='2' />
                  <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
                  <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
                  <feBlend mode='normal' in2='shape' result='effect1_innerShadow_314_5447' />
                </filter>
              </defs>
            </svg>

            <h2 style={{ color: '#0466C8' }}>
              타겟팅의 부재 {isMobile && <br />} (임대 vs 분양 = 사용자 vs 소유자)
            </h2>
          </div>

          <div className={'introduce3-list-item-wrapper'}>
            <svg xmlns='http://www.w3.org/2000/svg' width='26' height='33' viewBox='0 0 52 67' fill='none'>
              <g filter='url(#filter0_i_314_5449)'>
                <path
                  d='M16.28 38.11H29.06V28.3C29.06 24.07 29.51 17.68 29.69 13.45H29.33C27.62 17.23 25.82 21.1 23.93 24.88L16.28 38.11ZM51.47 38.11V50.26H44V67H29.06V50.26H0.98V39.19L24.29 0.0399984H44V38.11H51.47Z'
                  fill='#FFC120' />
              </g>
              <defs>
                <filter id='filter0_i_314_5449' x='0.980469' y='0.0400391' width='50.4893' height='70.96' filterUnits='userSpaceOnUse'
                        color-interpolation-filters='sRGB'>
                  <feFlood flood-opacity='0' result='BackgroundImageFix' />
                  <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
                  <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
                  <feOffset dy='4' />
                  <feGaussianBlur stdDeviation='2' />
                  <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
                  <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
                  <feBlend mode='normal' in2='shape' result='effect1_innerShadow_314_5449' />
                </filter>
              </defs>
            </svg>

            <h2 style={{ color: '#0466C8' }}>
              플레이어의 전문성 부재
            </h2>
          </div>
        </div>

        <h3 style={{ color: '#777', paddingLeft: isBrowser ? '20px' : 0,  lineHeight: '26px' }}>설명을 하자면 길지만 이 글의 목적은 성공적 {isMobile && <br/>} 평가의 이유에 있으니 짧게 넘어가겠습니다.</h3>

        <h1>문제 해결</h1>
        <img src={item2} alt={''} />

        <div className={'introduce3-desc-wrapper'}>
          <span>1번과 2번의 해결 방안</span>은 명확합니다. <strong> {isMobile && <br />}디자인을 잘하면 됩니다.</strong> <br />
          다행히 요즘은 능력 있는 젊은 건축가들이 {isMobile && <br/>}넘쳐 나고 있습니다. {isBrowser && <br/>}
          건축주들과 잘 매칭되지 {isMobile && <br/>}않아 건축주도 손해, 능력 있는 건축가들도 {isMobile && <br/>}손해를 보는 상황입니다. <br />
          <strong>좋은 건축가와 매칭하여 충분한 시간과 {isMobile && <br/>}노력을 들이면</strong> 쉽게 해결되는 문제입니다.
        </div>

        <img src={item3} alt={''} style={{ padding: '50px 0 20px 0'}} />
        <div className={'introduce3-desc-wrapper'}>
          <span>3번</span>은 매입하는 사람들이 실거주자가 {isMobile && <br/>}아니라는 점, 즉 대부분 갭투자 또는 임대 수익을 {isMobile && <br/>}위해 {isBrowser && <br/>}
          매입하는 경우가 많아, 건물에 퀄리티에는 {isMobile && <br/>}관심이 없다는 것입니다. <br />
          굳이 높은 품질을 요구받지 않는다라는 것입니다. <br />
          <br />
          하지만 일반적으로 생각하는 것과 달리 {isMobile && <br/>}<strong>건축물에 질이 분양, 임대가와 관계가 있다면{isMobile && <br/>}</strong> 어떨까요? {isBrowser && <br/>}
          실주거가 목적이 아닌 매입하는 {isMobile && <br/>}사람은 퀄리티에 관심이 없지만, <strong>실거주가 {isMobile && <br/>}목적인 임대인들은 </strong>{isBrowser && <br/>}
          <strong>퀄리티에 따라 좀 더 높은 {isMobile && <br/>}임대가를 지불할 의지가 있기 </strong> 때문입니다. <br />
          또한 <strong>임대가는 매매가와 강한 관계</strong>가 있습니다. <br />
          <br />
          즉 <span><strong>임대에 타겟팅</strong></span>을 해야 합니다.
        </div>

        <img src={item4} alt={''} style={{ padding: '50px 0 20px 0' }}/>
        <div className={'introduce3-desc-wrapper'}>
          <span>4번</span> <strong>플레이어의 문제</strong>입니다. {isMobile && <br/>}건축은 오랜 수련 기간이 필요하고, <br />
          전문성이 확보되어야 하는 영역이고{isMobile && <br/>} 다양한 분야의 협업이 필요한 분야입니다. <br />
          그런데 우리가 주로 보는 <strong>빌라를 개발하고 {isMobile && <br/>}공사/설계하는 사람 또는 회사들은 {isMobile && <br/>}영세하며</strong> {isBrowser && <br/>}
          <strong>(아파트에 비해) {isMobile && <br/>}비전문적이고 충분한 교육 또는 {isMobile && <br/>}경험을 가지고 있지 않다는 점</strong>입니다.
        </div>

        <h1 className={'result-wrapper'}>결론</h1>
        <div className={'introduce3-desc-wrapper'}>
          정리하자면 원론적인 이야기 같지만, {isMobile && <br/>}잘 설계해서, 임대인에 타겟팅 해서, <br />
          전문성이 있는 플레이어가 진행한다면 {isMobile && <br/>}<strong>같은 가격으로 흔히 보는 빌라보다 {isMobile && <br/>}훨씬 좋은 건물을</strong> {isBrowser && <br/>}
          <strong>지을 수 있고 이는 {isMobile && <br/>}수익의 상승과 연결이 된다</strong>고 할 수 있습니다. <br />
          <br />
          <span>구의동 다세대주택의 임대가를 {isMobile && <br/>}주변 시세와 비교</span>해 보겠습니다. <br />
          <br />
          전용면적을 기준으로 {isMobile && <br/>}구의동 다세대주택의 임대료는 {isMobile && <br/>}평당 12만원, 주변 시세는 9만원,  <br />
          <br />
          <strong>약 25% 높은 것을 알 수 있습니다.</strong> <br />
          <br />
          분양이 목적이 아니었기 때문에 {isMobile && <br/>}분양/매매가를 비교하긴 어렵지만 <br />
          임대가의 비교를 통해 매매가에도 {isMobile && <br/>}차이가 나는 것을 알 수 있습니다.
        </div>

        <svg xmlns='http://www.w3.org/2000/svg' width='140' height='60' viewBox='0 0 140 60' fill='none'>
          <path
            d='M19.094 44V18.84H27.356C32.932 18.84 37.216 20.778 37.216 26.728C37.216 32.474 32.898 35.058 27.492 35.058H24.126V44H19.094ZM24.126 31.08H27.152C30.62 31.08 32.286 29.618 32.286 26.728C32.286 23.804 30.45 22.818 26.982 22.818H24.126V31.08ZM51.7677 44.476C45.1037 44.476 40.5137 39.478 40.5137 31.318C40.5137 23.124 45.1037 18.364 51.7677 18.364C58.4317 18.364 63.0217 23.158 63.0217 31.318C63.0217 39.478 58.4317 44.476 51.7677 44.476ZM51.7677 40.124C55.5077 40.124 57.8877 36.69 57.8877 31.318C57.8877 25.912 55.5077 22.682 51.7677 22.682C48.0277 22.682 45.6477 25.912 45.6477 31.318C45.6477 36.69 48.0277 40.124 51.7677 40.124ZM67.9358 44V18.84H72.9678V44H67.9358ZM79.1585 44V18.84H84.2925L91.6025 32.236L94.1185 37.676H94.2885C94.0165 35.058 93.6425 31.726 93.6425 28.87V18.84H98.4025V44H93.3025L86.0265 30.536L83.4765 25.13H83.3065C83.5445 27.85 83.9185 31.012 83.9185 33.902V44H79.1585ZM109.623 44V23.022H102.551V18.84H121.795V23.022H114.655V44H109.623Z'
            fill='#FDC501' />
          <rect x='3' y='3' width='134' height='54' stroke='#FDC501' stroke-width='6' />
        </svg>

        <div className={'introduce3-desc-wrapper'}>
          <span><strong>같은 금액의 투자를 통해</strong></span> {isBrowser && <br/>}
          <span><strong>25% {isMobile && <br/>}높은 수익률을 올릴 수 있었다는 것으로 {isMobile && <br/>}결론</strong></span>을 가름합니다.
        </div>
      </section>
    </div>
  );
}
