import React, { useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import './Introduce1.scss';
import TitleAbout from '../../statics/images/v3/bg-aboutus.jpg';

import item11 from '../../statics/images/v3/introduce/1/img-item11.png';
import item12 from '../../statics/images/v3/introduce/1/img-item12.png';
import item13 from '../../statics/images/v3/introduce/1/img-item13.png';
import item14 from '../../statics/images/v3/introduce/1/img-item14.png';
import item15 from '../../statics/images/v3/introduce/1/img-item15.png';
import item16 from '../../statics/images/v3/introduce/1/img-item16.png';

import item21 from '../../statics/images/v3/introduce/1/img-item21.png';
import item22 from '../../statics/images/v3/introduce/1/img-item22.png';
import item23 from '../../statics/images/v3/introduce/1/img-item23.png';
import item24 from '../../statics/images/v3/introduce/1/img-item24.png';
import item25 from '../../statics/images/v3/introduce/1/img-item25.png';

import item31 from '../../statics/images/v3/introduce/1/img-item31.png';
import item32 from '../../statics/images/v3/introduce/1/img-item32.png';
import item33 from '../../statics/images/v3/introduce/1/img-item33.png';
import item34 from '../../statics/images/v3/introduce/1/img-item34.png';

import item41 from '../../statics/images/v3/introduce/1/img-item41.png';
import item42 from '../../statics/images/v3/introduce/1/img-item42.png';
import item43 from '../../statics/images/v3/introduce/1/img-item43.png';
import item44 from '../../statics/images/v3/introduce/1/img-item44.png';

import item11m from '../../statics/images/v3/introduce/1/img-m-item11.png';
import item12m from '../../statics/images/v3/introduce/1/img-m-item12.png';
import item13m from '../../statics/images/v3/introduce/1/img-m-item13.png';
import item14m from '../../statics/images/v3/introduce/1/img-m-item14.png';
import item15m from '../../statics/images/v3/introduce/1/img-m-item15.png';
import item16m from '../../statics/images/v3/introduce/1/img-m-item16.png';
import item17m from '../../statics/images/v3/introduce/1/img-m-item17.png';
import item18m from '../../statics/images/v3/introduce/1/img-m-item18.png';


import { isBrowser, isMobile } from 'react-device-detect';
import Carousel from 'nuka-carousel';
import { useState } from 'react';
import { useEffect } from 'react';
import { useWindowScroll } from '../../utilities/useWindowScroll';

export default function Introduce1(props: RouteComponentProps) {
  const [scroll] = useWindowScroll();
  const [position, setPosition] = useState({
    section1: 0, section2: 0, section3: 0, section4: 0,
  });

  useEffect(() => {
    const header = document.querySelector('.header') as HTMLDivElement;
    const section1 = window.document.querySelector('.introduce1-right-content1-wrapper .title-wrapper') as HTMLElement;
    const section2 = window.document.querySelector('.introduce1-right-content2-wrapper .title-wrapper') as HTMLElement;
    const section3 = window.document.querySelector('.introduce1-right-content3-wrapper .title-wrapper') as HTMLElement;
    const section4 = window.document.querySelector('.introduce1-right-content4-wrapper .title-wrapper') as HTMLElement;

    setPosition({
      section1: section1.offsetTop - header.offsetHeight + (isBrowser ? 400 : 0),
      section2: section2.offsetTop - header.offsetHeight + (isBrowser ? 350 : 100),
      section3: section3.offsetTop - header.offsetHeight + (isBrowser ? 280 : 100),
      section4: section4.offsetTop - header.offsetHeight + (isBrowser ? 220 : 100),
    });
  }, [scroll, isBrowser]);

  useEffect(() => {
    const header = document.querySelector('.introduce1-category-wrapper') as HTMLDivElement;

    if (header) {
      if (scroll > 20) {
        header.style.top = '48px';
      } else {
        header.style.top = '98px';
      }
    }
  }, [scroll]);

  useEffect(() => {
    const header = document.querySelector('.introduce1-category-wrapper') as HTMLDivElement;
    let preScrollTop = 0;

    if (header) {
      window.addEventListener('scroll', (event) => {
        let nextScrollTop = window.scrollY;

        if(preScrollTop < nextScrollTop) {
          header.style.display = 'none';
        }
        else { // (preScrollTop > nextScrollTop)
          header.style.display = 'flex';
        }
        preScrollTop = nextScrollTop;
      });
    }

    return () => {
      window.removeEventListener('scroll', () => {
      });
    };
  }, []);

  const onMove = useCallback((section: string) => {
    const div = document.querySelector('.introduce1-circle-category-wrapper ul') as HTMLDivElement;
    let y = 0;

    if (section === '1') {
      y = position['section1'];
    } else if (section === '2') {
      y = position['section2'];
    } else if (section === '3') {
      y = position['section3'];
    } else if (section === '4') {
      y = position['section4'];
    }

    window.scrollTo(0, y);
    div.style.display = 'none';
    setTimeout(() => {
      div.style.display = '';
    }, 500);
  }, [position]);

  return (
    <div className='introduce1-wrapper'>
      <div className='common-header'>
        <img src={TitleAbout} alt='' />
        <h1>부동산 공동 개발?</h1>
      </div>

      <div className='introduce1-main-wrapper'>
        {
          isBrowser ?
            <div className='introduce1-left-wrapper'>
              <ul>
                <li onClick={() => onMove('1')} className={(0 <= scroll && !(position?.section2 <= scroll)) ? 'active' : ''}>200만원 부동산개발?</li>
                <li onClick={() => onMove('2')} className={position?.section2 <= scroll && !(position?.section3 <= scroll) ? 'active' : ''}>부동산 공동
                  개발
                  프로젝트?
                </li>
                <li onClick={() => onMove('3')} className={position?.section3 <= scroll && !(position?.section4 <= scroll) ? 'active' : ''}>지오팝이란?
                </li>
                <li onClick={() => onMove('4')} className={position?.section4 <= scroll ? 'active' : ''}>소중한 내 돈 안전한가?</li>
              </ul>

              <div className='introduce1-circle-category-wrapper'>
                <div className='introduce1-circle-category-item'>MENU</div>

                <ul>
                  <li onClick={() => onMove('1')} className={(0 <= scroll && !(position?.section2 <= scroll)) ? 'active' : ''}>200만원 부동산개발?</li>
                  <li onClick={() => onMove('2')} className={position?.section2 <= scroll && !(position?.section3 <= scroll) ? 'active' : ''}>부동산 공동
                    개발
                    프로젝트?
                  </li>
                  <li onClick={() => onMove('3')} className={position?.section3 <= scroll && !(position?.section4 <= scroll) ? 'active' : ''}>지오팝이란?
                  </li>
                  <li onClick={() => onMove('4')} className={position?.section4 <= scroll ? 'active' : ''}>소중한 내 돈 안전한가?</li>
                </ul>
              </div>
            </div>
            :
            <div className='introduce1-category-wrapper'>
              <ul>
                <li onClick={() => onMove('1')}>200만원 부동산개발?</li>
                <li onClick={() => onMove('2')}>부동산 공동 개발 프로젝트?</li>
                <li onClick={() => onMove('3')}>지오팝이란?</li>
                <li onClick={() => onMove('4')}>소중한 내 돈 안전한가?</li>
              </ul>
            </div>
        }

        <div className='introduce1-right-wrapper'>

          <div className='introduce1-right-content1-wrapper'>
            <div className='title-wrapper'>
              <h1>200만원으로 어떻게 부동산 개발을 할 수 있지?</h1>
              <span>엄청 간단해요!</span>
            </div>

            <div className='desc-wrapper'>
              {
                isBrowser ?
                  <>
                    <img src={item11} alt={'item11'} />
                    <img src={item12} alt={'item12'} />
                  </>
                  :
                  <img src={item11m} alt={'item11m'} />
              }
              <span>1. ‘부동산개발 참여’ 메뉴에서 ‘조합원 모집’ 클릭</span>

              {
                isBrowser ?
                  <img src={item13} alt={'item13'} />
                  :
                  <>
                    <img src={item12m} alt={'item12m'} />
                    <img src={item13m} alt={'item13m'} />
                    <img src={item14m} alt={'item14m'} />
                  </>
              }

              <span>2. 그라운드컨트롤의 전문가들이 엄선한 부동산 개발 중 마음에 드는 프로젝트를 선택</span>

              {
                isBrowser ?
                  <img src={item14} alt={'item14'} />
                  :
                  <>
                    <img src={item15m} alt={'item15m'} />
                    <img src={item16m} alt={'item16m'} />
                  </>
              }
              <span>3. 개발 내용을 꼼꼼히 살펴본 후 참여할 조합지분(구좌) 수를 정하고</span>

              {
                isBrowser ?
                  <img src={item15} alt={'item15'} />
                  :
                  <img src={item17m} alt={'item17m'} />
              }
              <span>4. 참여버튼 클릭!</span>

              {
                isBrowser ?
                  <img src={item16} alt={'item16'} />
                  :
                  <img src={item18m} alt={'item18m'} />
              }
              <span>5. 이후 필수사항에 동의하고 안내에 따라 진행하면 끝!</span>
            </div>
          </div>

          <div className='introduce1-right-content2-wrapper'>
            <div className='title-wrapper'>
              <h1>부동산 공동 개발 프로젝트?</h1>
            </div>

            <div className='desc-wrapper'>
              <div className='desc-title-wrapper'>
                <h1>부동산 개발이란?</h1>
                <span>부동산(토지 혹은 건물)을 사서 {isMobile && <br/>} 새로운 건축물을 지어 개발하는 것을 칭합니다.</span>
              </div>

              {
                isMobile ?
                  <div className='items-wrapper'>
                    <Carousel slidesToShow={1} cellSpacing={20}
                              className='carousel'
                              defaultControlsConfig={{
                                nextButtonStyle: {
                                  display: 'none',
                                },
                                prevButtonStyle: {
                                  display: 'none',
                                },
                                pagingDotsStyle: {
                                  display: 'none',
                                },
                              }}
                    >
                      <div className='item-wrapper'>
                        <img src={item21} alt={'item21'} />
                        <span>부동산 개발은 수익이 높지만</span>
                      </div>

                      <div className='item-wrapper'>
                        <img src={item22} alt={'item22'} />
                        <span>개인이 혼자 하기에는 돈이 많이 들고,</span>
                      </div>

                      <div className='item-wrapper'>
                        <img src={item23} alt={'item23'} />
                        <span>과정이 복잡하고,</span>
                      </div>

                      <div className='item-wrapper'>
                        <img src={item24} alt={'item24'} />
                        <span>전문가의 영역인거 같아 접근이 힘들다</span>
                      </div>
                    </Carousel>
                  </div> :
                  <div className='items-wrapper'>
                    <div className='item-wrapper'>
                      <img src={item21} alt={'item21'} />
                      <span>부동산 개발은 수익이 높지만</span>
                    </div>

                    <div className='item-wrapper'>
                      <img src={item22} alt={'item22'} />
                      <span>개인이 혼자 하기에는 돈이 많이 들고,</span>
                    </div>

                    <div className='item-wrapper'>
                      <img src={item23} alt={'item23'} />
                      <span>과정이 복잡하고,</span>
                    </div>

                    <div className='item-wrapper'>
                      <img src={item24} alt={'item24'} />
                      <span>전문가의 영역인거 같아 접근이 힘들다</span>
                    </div>
                  </div>
              }
            </div>

            <div className='title1-wrapper'>
              <h1>
                이러한 어렵고 복잡한 부동산 개발을 <br />
                <strong>전문가와 함께! 공동의 참여자들과 함께!</strong>
              </h1>
            </div>
          </div>

          <div className='introduce1-right-content21-wrapper'>
            <img src={item25} alt={'item25'} />

            <h1>
              여럿이 함께해서 <strong>부담은 줄이고</strong> <br />
              부동산 개발의 <strong>수익률은 그대로</strong>
            </h1>
          </div>

          <div className='introduce1-right-content3-wrapper'>
            <div className='title-wrapper'>
              <h1>지오팝은 뭐 하는 곳이죠? {isMobile && <br />} 왜 필요해?</h1>
            </div>

            <div className='desc-wrapper'>
              <div className='items-wrapper'>
                <div className='item-wrapper'>
                  <img src={item31} alt={'item31'} />

                  <div>
                    <h1>부동산 공동개발에 필요한</h1>
                    <span>
                      1. 부동산 개발에 관심있는 {!isBrowser && <><br />{'\u00A0\u00A0\u00A0\u00A0'}</>} 사람들이 모여 {isBrowser && <br />}
                      {isBrowser && '\u00A0\u00A0\u00A0\u00A0\u00A0'}프로젝트를 {!isBrowser && <><br />{'\u00A0\u00A0\u00A0\u00A0'}</>} 함께 진행할 수 있는 모집의 {!isBrowser && <><br />{'\u00A0\u00A0\u00A0\u00A0\u00A0'}</>}장 마련
                    </span>
                  </div>
                </div>

                <div className='item-wrapper'>
                  <img src={item32} alt={'item32'} />
                  <span>
                    2. 개발가치가 있는 매력적 {!isBrowser && <><br />{'\u00A0\u00A0\u00A0\u00A0'}</>}매물을 발굴하여 {isBrowser && <br />}
                    {isBrowser && '\u00A0\u00A0\u00A0\u00A0\u00A0'}모두가 참여{!isBrowser && <><br />{'\u00A0\u00A0\u00A0\u00A0'}</>}가능한 프로젝트를 만들고
                  </span>
                </div>

                <div className='item-wrapper'>
                  <img src={item33} alt={'item33'} />
                  <span>
                    3. 참여한 조합과 프로젝트가 {!isBrowser && <><br />{'\u00A0\u00A0\u00A0\u00A0'}</>}설계, 건설, 매매 등 {isBrowser && <br />}
                    {isBrowser && '\u00A0\u00A0\u00A0\u00A0\u00A0'}주요{!isBrowser && <><br />{'\u00A0\u00A0\u00A0\u00A0'}</>}사항의 결정은 조합투표를 {!isBrowser && <><br />{'\u00A0\u00A0\u00A0\u00A0'}</>}통해 투명하게 진행되고
                  </span>
                </div>

                <div className='item-wrapper'>
                  <img src={item34} alt={'item34'} />
                  <span>
                    4. 이에 필요한 제반 사항들을{!isBrowser && <><br />{'\u00A0\u00A0\u00A0\u00A0'}</>} 챙겨 프로젝트가 {isBrowser && <br />}
                    {isBrowser && '\u00A0\u00A0\u00A0\u00A0\u00A0'}성공적으로{!isBrowser && <><br />{'\u00A0\u00A0\u00A0\u00A0'}</>} 완료되도록 노력합니다.
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className='introduce1-right-content4-wrapper'>
            <div className='title-wrapper'>
              <h1>소중한 내 돈 {isMobile && <br />} 어떻게 안전하게 관리되나요?</h1>
            </div>

            <div className='desc-wrapper'>
              <div className='items-wrapper'>
                <div className='item-wrapper'>
                  <img src={item41} alt={'item41'} />

                  <div>
                    <h1>
                      지오팝의 서비스와 {isBrowser && <br />}
                      부동산 {isMobile && <br />} 공동 개발 사업모델은
                    </h1>

                    <span>
                      국내 최고의 부동산 전문 법률 {isMobile && <br />} 사무소와 {isBrowser && <br />}
                      회계법인의 {isMobile && <br />} 법무/회계/세무 검토를 받았습니다.
                    </span>
                  </div>
                </div>

                <div className='item-wrapper'>
                  <div>
                    <span>
                      참여하신 개발 프로젝트는 <br />
                      (주)그라운드컨트롤이 엄선한 {isMobile && <br />}추천 업체들과
                    </span>

                    <h1>
                      <strong>
                        참여자(조합원)의 {isMobile && <br />} 투표를 통해 <br />
                        함께 하실 수 있습니다.
                      </strong>
                    </h1>
                  </div>

                  <img src={item42} alt={'item42'} />
                </div>

                <div className='item-wrapper'>
                  <img src={item43} alt={'item43'} />

                  <div>
                    <span>
                      모집된 조합의 자금은 {isMobile && <br />} 별도 계좌에 {isBrowser && <br />}
                      입금되어 {isMobile && <br />}프로젝트 시작과 함께
                    </span>

                    <h1>
                      국내 최고 수준의 {isMobile && <br />} 신탁사에서 {isBrowser && <br />}
                      안전하게 {isMobile && <br />} 관리됩니다.
                    </h1>
                  </div>
                </div>

                <div className='item-wrapper'>
                  <div>
                    <h1>
                      <strong>또한 프로젝트의 자산인 {isMobile && <br />} 토지와 건축물은 <br />
                        조합이 법적으로 소유</strong>하여
                    </h1>

                    <span>
                      운영업체와는 전혀 관련이 없으므로 <br />
                      안심하셔도 됩니다.
                    </span>
                  </div>

                  <img src={item44} alt={'item44'} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
